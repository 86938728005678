import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { VueMaskDirective } from 'v-mask';
import Keycloak from "keycloak-js";
import {AppConstant} from "./app.constant";


const keycloakOptions = {
  url: process.env.VUE_APP_OAUTH_URL,
  realm: process.env.VUE_APP_OAUTH_REALM,
  clientId: process.env.VUE_APP_OAUTH_CLIENT,
  onLoad: "check-sso",
  checkLoginIframe: false
};
const keycloak = Keycloak(keycloakOptions);
Vue.prototype.$keycloak = keycloak;
Vue.directive('mask',VueMaskDirective);

Vue.config.productionTip = false;
router.beforeEach((to,from,next) =>{
  //@ts-ignore: Unreachable code error
  if(to.meta.role != undefined && !hasRole(to.meta.role)){
    keycloak.login({redirectUri: `${process.env.VUE_APP_KEYCLOAK_LOGOUT_REDIRECT_URL}/#${to.path}`});
  }else{
    next();
  }
})

keycloak.init({
  onLoad: "check-sso",
  checkLoginIframe: false
}).then((auth: boolean) => {
  console.log(auth)
  if (!auth) {
    console.log("Logging out if not already.")
    localStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
    sessionStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
    store.commit("logout");
    store.dispatch("logout").then();
  } else {
    localStorage.setItem(AppConstant.AUTH_TOKEN_NAME, <string>keycloak.token);
    sessionStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
    retrieveDetails(auth);
  }

  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
  }).$mount('#app')

}).catch(() => {
  console.log("Authenticated Failed");
});

function retrieveDetails(auth:boolean): void {
  keycloak.loadUserProfile().then((res: any) => {
    console.log(`CHECKING TOKEN>>>>`)
    store.commit("addKeycloak", keycloak)
    store.commit("login", keycloak.token)
    store.commit("addUsername", res.username)
    store.commit("addAuthenticated", auth)
    store.commit("addRoles", (keycloak.resourceAccess as any)[keycloakOptions.clientId].roles)
  })
}

function hasRole(role:string):boolean{
  return store.state.roles.includes(role as never,0)
}

function refreshKeycloakToken() {
  keycloak.updateToken(70).then((refreshed) => {
    if (refreshed) {
      console.log('Token refreshed: ' + refreshed);
      localStorage.setItem(AppConstant.AUTH_TOKEN_NAME, <string>keycloak.token);
      sessionStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
    } else {
      let timeUntilExpiration = 0;
      const keycloakTokenParsedExp = keycloak?.tokenParsed?.exp ? keycloak?.tokenParsed?.exp : 0;
      const keycloakTimeSkew = keycloak?.timeSkew ? keycloak?.timeSkew : 0
      timeUntilExpiration = Math.round(keycloakTokenParsedExp + keycloakTimeSkew - new Date().getTime() / 1000)
      console.log('Token not refreshed, valid for '
          + timeUntilExpiration + ' seconds');
    }
  }).catch(() => {
    console.log('Failed to refresh token');
  });
}

declare module 'vue/types/vue' {
  interface Vue {
    $keycloak: typeof Vue.prototype.$keycloak
  }

}
